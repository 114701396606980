.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-row {
  align-items: flex-start;
}

.video-center {
  width: 200px;
}

.address {
  font-size: 0.8em;
}

.logo {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.connect-wallet {
  display: flex;
  flex: 1 1 0%;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

@media screen and (max-width: 700px) {
  .video-center {
       width: 100%;
  }
}
